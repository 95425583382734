/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: Date | string; output: string; }
  Duration: { input: string; output: string; }
  JSON: { input: any; output: any; }
  SemVer: { input: string; output: string; }
  Upload: { input: unknown; output: unknown; }
};

export type AcceptInviteInput = {
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  invite: Scalars['String']['input'];
};

export type AddClientInput = {
  email: Scalars['String']['input'];
};

export type AddressInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  postcode: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type AnswerInput = {
  answer: Scalars['JSON']['input'];
  consultationId?: InputMaybe<Scalars['Int']['input']>;
  questionId: Scalars['Int']['input'];
};

export type BillingInformationInput = {
  accountNumber: Scalars['String']['input'];
  address: AddressInput;
  notificationEmail?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export type CalendarEventInput = {
  allDay: Scalars['Boolean']['input'];
  returnAsFullCalendar?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledFrom: Scalars['DateTime']['input'];
  scheduledTo: Scalars['DateTime']['input'];
  type: Scalars['String']['input'];
};

export type CalendarEventTypes =
  | 'availability'
  | 'consultation_appointment'
  | 'consultation_intro'
  | 'online'
  | 'unknown_type';

export type CancelConsultationInput = {
  /** The id of the consultation to be cancelled */
  id: Scalars['Int']['input'];
  /** A message for the other party in the consultation */
  message: Scalars['String']['input'];
};

/** These are the statuses from Mollie (https://docs.mollie.com/payments/status-changes) with the addition of 'new' which we use for fresh transactions. */
export type CashTransactionStatusType =
  | 'authorized'
  | 'canceled'
  | 'expired'
  | 'failed'
  | 'new'
  | 'open'
  | 'paid'
  | 'pending'
  | 'refunded';

export type ChoosePasswordV2Input = {
  /** A user's e-mail address */
  email: Scalars['String']['input'];
  /** The value the password should be set to. */
  newPassword: Scalars['String']['input'];
  /** A token that was generated by the resetPasswordV2 mutation and email */
  resetToken: Scalars['String']['input'];
};

export type ClientStatus =
  | 'CURRENT'
  | 'DECLINED'
  | 'INVITED'
  | 'ONLY_INTRO'
  | 'PAST'
  | 'PENDING';

export type ConfirmConsultationInput = {
  email: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type ConsultationFilter = {
  status?: InputMaybe<Array<ConsultationStatus>>;
};

export type ConsultationStatus =
  | 'ACCEPTED'
  | 'CANCELLED'
  | 'CANCELLED_BY_HUMAN'
  | 'CANCELLED_BY_PROFESSIONAL'
  | 'DONE'
  | 'IN_PROGRESS'
  | 'NO_SHOW_HUMAN'
  | 'NO_SHOW_PROFESSIONAL'
  | 'REQUESTED'
  | 'WAITING';

export type ConsultationType =
  | 'APPOINTMENT'
  | 'CHECKIN'
  | 'INTRO';

export type ContinueTrackInput = {
  id: Scalars['String']['input'];
  /**
   * When provided will link the current user to the provided professional for the track.
   * Should be part of the Input for the frist checkin node, but for now is here and optional.
   */
  professionalId?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
  values?: InputMaybe<Scalars['JSON']['input']>;
};

export type CopyCalendarEventsInput = {
  copyTo: Scalars['DateTime']['input'];
  from: Scalars['DateTime']['input'];
  onlyType?: InputMaybe<Scalars['String']['input']>;
  returnAsFullCalendar?: InputMaybe<Scalars['Boolean']['input']>;
  to: Scalars['DateTime']['input'];
};

export type CreateConsultationInput = {
  fromMatching: Scalars['Boolean']['input'];
  matchedHumanId?: InputMaybe<Scalars['Int']['input']>;
  otherPartyId: Scalars['Int']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  scheduledFrom: Scalars['DateTime']['input'];
  scheduledTo: Scalars['DateTime']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  type: ConsultationType;
};

export type DeleteCalendarEventsInput = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type Enable2FaInput = {
  secret: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type EntryPoints =
  | 'after_consultation'
  | 'after_intro'
  | 'after_last_planned_consultation'
  | 'before_consultation'
  | 'during_self_help'
  | 'end_of_treatment'
  | 'in_app_notification';

export type EntryPointsInput = {
  entrypoint: EntryPoints;
};

export type HumanInput = {
  address?: InputMaybe<AddressInput>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  healthInsurance?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  onboardedAt?: InputMaybe<Scalars['DateTime']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  /** The gender of a professional that a Human prefers to work with. */
  preferredProfessionalGender?: InputMaybe<Scalars['String']['input']>;
  /** Professionals that are linked to this Human. */
  professionals?: InputMaybe<Array<Scalars['Int']['input']>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type Language =
  | 'en'
  | 'frBe'
  | 'nl'
  | 'nlBe';

export type LoginFailureReason =
  | 'INCORRECT_2FA'
  | 'INCORRECT_CREDENTIALS'
  | 'NEEDS_2FA';

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginV2Input = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token2FA?: InputMaybe<Scalars['String']['input']>;
};

export type LoginWithSsoInput = {
  authorizationCode: Scalars['String']['input'];
  mindlabBusinessId?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MatchedHumanInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  chosenProfessionalId?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  preferredProfessionalGender?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['String']['input']>;
};

export type MatchedHumanWorriesInput = {
  /** Specialties should be an array of uuids of SpecialtiesThemeCombo's */
  specialtiesThemeComboIds: Array<Scalars['String']['input']>;
};

export type MessageInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  fromProfessional?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MobileStatus =
  | 'FORCE_UPDATE'
  | 'SUPPORTED'
  | 'WARN_UPDATE';

export type NodeButtonType =
  | 'continue'
  | 'start';

export type Notification = {
  body: Scalars['String']['input'];
  deep_link?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  renotify?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type PaymentInput = {
  cashTransactionId: Scalars['Int']['input'];
};

export type PermanentHumanInput = {
  contactForFeedback: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  healthInsurance?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};

export type PracticeInput = {
  address?: InputMaybe<AddressInput>;
  chatTherapy?: InputMaybe<Scalars['Boolean']['input']>;
  offlineTherapy?: InputMaybe<Scalars['Boolean']['input']>;
  onlineTherapy?: InputMaybe<Scalars['Boolean']['input']>;
  phoneTherapy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProfessionalInput = {
  acceptedConsent?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  billingInformation?: InputMaybe<BillingInformationInput>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  clientStop?: InputMaybe<Scalars['Boolean']['input']>;
  cmNumber?: InputMaybe<Scalars['String']['input']>;
  consultationLanguages?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  experienceSince?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  isFirstLine?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseFiles?: InputMaybe<Array<Scalars['Upload']['input']>>;
  onboardedAt?: InputMaybe<Scalars['DateTime']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  practice?: InputMaybe<PracticeInput>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<Scalars['String']['input']>;
  specialties?: InputMaybe<Scalars['String']['input']>;
  standardDuration?: InputMaybe<Scalars['Int']['input']>;
  standardRate?: InputMaybe<Scalars['Float']['input']>;
  supervision?: InputMaybe<Scalars['Boolean']['input']>;
  therapyTypes?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  training?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  visumNumber?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type ProfessionalRegistrationInput = {
  acceptedConsent?: InputMaybe<Scalars['Boolean']['input']>;
  consultationLanguages?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseFiles?: InputMaybe<Array<Scalars['Upload']['input']>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  training?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type ProfessionalSpecialtiesInput = {
  /** Specialties should be an array of uuids of SpecialtiesThemeCombo's */
  specialtiesThemeComboIds: Array<Scalars['String']['input']>;
};

export type ProfessionalsForCurrentHumanInput = {
  withAvailability?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProfessionalsInput = {
  adminsIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  adminsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  verifiedIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  verifiedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuestionsCategory =
  | 'b2b'
  | 'call_quality'
  | 'end_of_treatment'
  | 'match_quality'
  | 'nps'
  | 'professional_quality'
  | 'share_with_bloomup';

export type QuestionsComponent =
  | 'checkbox'
  | 'dropdown'
  | 'radio'
  | 'toggle';

export type QuestionsType =
  | 'likert'
  | 'multi_select'
  | 'open'
  | 'single_select'
  | 'slider'
  | 'yes_no'
  | 'yes_no_maybe';

export type RegisterHumanInput = {
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  fromMindlab?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mindlabNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  organizationCode?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  preferredLanguage: Language;
};

export type ReimbursementInput = {
  consultationUuid: Scalars['String']['input'];
  nationalId: Scalars['String']['input'];
};

export type RequestConsultationAsHumanInput = {
  fromMatching: Scalars['Boolean']['input'];
  professionalId: Scalars['Int']['input'];
  startTime: Scalars['DateTime']['input'];
  type: ConsultationType;
};

export type RequestedByType =
  | 'HUMAN'
  | 'PROFESSIONAL';

/** Input object for rescheduling a consultation. */
export type RescheduleConsultationInput = {
  id: Scalars['Int']['input'];
  message: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  scheduledFrom: Scalars['DateTime']['input'];
  scheduledTo: Scalars['DateTime']['input'];
};

export type SortDirection =
  | 'asc'
  | 'desc';

export type StartMatchingInput = {
  extraInfo?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type StartMatchingV2Input = {
  age: Scalars['Int']['input'];
  orgCode?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitLocationStepInput = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  regionId?: InputMaybe<Scalars['String']['input']>;
};

export type TrackSectionType =
  | 'phase'
  | 'videoCall';

export type TrackType =
  | 'assessment'
  | 'exercise'
  | 'growthTrack';

export type Treatment =
  | 'COACHING'
  | 'SELFHELP'
  | 'THERAPY';

export type UpdateCalendarEventInput = {
  allDay?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  returnAsFullCalendar?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledFrom?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledTo?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConsultationInput = {
  fromMatching?: InputMaybe<Scalars['Boolean']['input']>;
  humanCallQuality?: InputMaybe<Scalars['Int']['input']>;
  humanNps?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<MessageInput>;
  price?: InputMaybe<Scalars['Float']['input']>;
  professionalCallQuality?: InputMaybe<Scalars['Int']['input']>;
  scheduledFrom?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledTo?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<ConsultationStatus>;
  therapistWithoutBloomup?: InputMaybe<Scalars['Boolean']['input']>;
  userRequested?: InputMaybe<Scalars['Boolean']['input']>;
  uuid: Scalars['String']['input'];
};

export type UpdateCurrentUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mindlabNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateProfessionalSubtopicsInput = {
  primary: Scalars['Boolean']['input'];
  subtopicIds: Array<Scalars['String']['input']>;
};

export type ConsultationOrder = {
  scheduledFrom: SortDirection;
};

export type JoinOrgByEmailOrCodeHomeMutationVariables = Exact<{
  emailOrCode: Scalars['String']['input'];
}>;


export type JoinOrgByEmailOrCodeHomeMutation = { __typename?: 'Mutation', joinOrganizationByEmailOrCodeV2?: boolean | null };

export type LoginMutationVariables = Exact<{
  input: LoginV2Input;
}>;


export type LoginMutation = { __typename?: 'Mutation', loginV2: { __typename?: 'LoginV2FailureResponse', reason: LoginFailureReason } | { __typename?: 'TokenResponse', accessToken?: string | null, mindlabToken?: string | null, refreshToken?: string | null } };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  fromMindlab?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type ChoosePasswordMutationVariables = Exact<{
  input: ChoosePasswordV2Input;
}>;


export type ChoosePasswordMutation = { __typename?: 'Mutation', choosePasswordV2: boolean };

export type Request2FaSecretsMutationVariables = Exact<{
  password: Scalars['String']['input'];
}>;


export type Request2FaSecretsMutation = { __typename?: 'Mutation', request2FASecrets: { __typename?: 'Secrets2FA', qrCode: string, secret: string } };

export type Enable2FaMutationVariables = Exact<{
  input: Enable2FaInput;
}>;


export type Enable2FaMutation = { __typename?: 'Mutation', enable2FA: boolean };

export type JoinOrgByEmailOrCodeProfileMutationVariables = Exact<{
  emailOrCode: Scalars['String']['input'];
}>;


export type JoinOrgByEmailOrCodeProfileMutation = { __typename?: 'Mutation', joinOrganizationByEmailOrCodeV2?: boolean | null };

export type UpdateCurrentUserMutationVariables = Exact<{
  input: UpdateCurrentUserInput;
}>;


export type UpdateCurrentUserMutation = { __typename?: 'Mutation', updateCurrentUser: { __typename?: 'User', firstName?: string | null, id: string, lastName?: string | null, mindlabNewsletter?: boolean | null, preferredLanguage: Language } };

export type RegisterHumanMutationVariables = Exact<{
  input: RegisterHumanInput;
}>;


export type RegisterHumanMutation = { __typename?: 'Mutation', registerHumanV2: { __typename?: 'TokenResponse', accessToken?: string | null, mindlabToken?: string | null, refreshToken?: string | null } };

export type GetSsoUrlQueryQueryVariables = Exact<{
  mindlabBusinessId: Scalars['String']['input'];
}>;


export type GetSsoUrlQueryQuery = { __typename?: 'Query', getSSOUrl: string };

export type LoginWithSsoMutationVariables = Exact<{
  input: LoginWithSsoInput;
}>;


export type LoginWithSsoMutation = { __typename?: 'Mutation', loginWithSSO: { __typename?: 'LoginV2FailureResponse' } | { __typename?: 'TokenResponse', accessToken?: string | null, mindlabToken?: string | null, refreshToken?: string | null } };

export type ResetPasswordOnProfileMutationVariables = Exact<{
  email: Scalars['String']['input'];
  fromMindlab?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ResetPasswordOnProfileMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type RefreshAccessTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']['input'];
}>;


export type RefreshAccessTokenMutation = { __typename?: 'Mutation', refreshAccessTokenV2: { __typename?: 'TokenResponse', accessToken?: string | null, refreshToken?: string | null } };


export const JoinOrgByEmailOrCodeHomeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"joinOrgByEmailOrCodeHome"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"emailOrCode"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"joinOrganizationByEmailOrCodeV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"emailOrCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"emailOrCode"}}}]}]}}]} as unknown as DocumentNode<JoinOrgByEmailOrCodeHomeMutation, JoinOrgByEmailOrCodeHomeMutationVariables>;
export const LoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Login"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LoginV2Input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loginV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TokenResponse"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessToken"}},{"kind":"Field","name":{"kind":"Name","value":"mindlabToken"}},{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LoginV2FailureResponse"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reason"}}]}}]}}]}}]} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"fromMindlab"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"fromMindlab"},"value":{"kind":"Variable","name":{"kind":"Name","value":"fromMindlab"}}}]}]}}]} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ChoosePasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"choosePassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChoosePasswordV2Input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"choosePasswordV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<ChoosePasswordMutation, ChoosePasswordMutationVariables>;
export const Request2FaSecretsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Request2FASecrets"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"request2FASecrets"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"qrCode"}},{"kind":"Field","name":{"kind":"Name","value":"secret"}}]}}]}}]} as unknown as DocumentNode<Request2FaSecretsMutation, Request2FaSecretsMutationVariables>;
export const Enable2FaDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Enable2FA"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Enable2FAInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enable2FA"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<Enable2FaMutation, Enable2FaMutationVariables>;
export const JoinOrgByEmailOrCodeProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"joinOrgByEmailOrCodeProfile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"emailOrCode"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"joinOrganizationByEmailOrCodeV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"emailOrCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"emailOrCode"}}}]}]}}]} as unknown as DocumentNode<JoinOrgByEmailOrCodeProfileMutation, JoinOrgByEmailOrCodeProfileMutationVariables>;
export const UpdateCurrentUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateCurrentUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateCurrentUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateCurrentUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"mindlabNewsletter"}},{"kind":"Field","name":{"kind":"Name","value":"preferredLanguage"}}]}}]}}]} as unknown as DocumentNode<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>;
export const RegisterHumanDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RegisterHuman"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RegisterHumanInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"registerHumanV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessToken"}},{"kind":"Field","name":{"kind":"Name","value":"mindlabToken"}},{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}}]}}]}}]} as unknown as DocumentNode<RegisterHumanMutation, RegisterHumanMutationVariables>;
export const GetSsoUrlQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getSSOUrlQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mindlabBusinessId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getSSOUrl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mindlabBusinessId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mindlabBusinessId"}}}]}]}}]} as unknown as DocumentNode<GetSsoUrlQueryQuery, GetSsoUrlQueryQueryVariables>;
export const LoginWithSsoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LoginWithSSO"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LoginWithSSOInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loginWithSSO"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TokenResponse"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessToken"}},{"kind":"Field","name":{"kind":"Name","value":"mindlabToken"}},{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}}]}}]}}]}}]} as unknown as DocumentNode<LoginWithSsoMutation, LoginWithSsoMutationVariables>;
export const ResetPasswordOnProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetPasswordOnProfile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"fromMindlab"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"fromMindlab"},"value":{"kind":"Variable","name":{"kind":"Name","value":"fromMindlab"}}}]}]}}]} as unknown as DocumentNode<ResetPasswordOnProfileMutation, ResetPasswordOnProfileMutationVariables>;
export const RefreshAccessTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RefreshAccessToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"refreshToken"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"refreshAccessTokenV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"refreshToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"refreshToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessToken"}},{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}}]}}]}}]} as unknown as DocumentNode<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>;